<template>
  <v-card>
    <v-system-bar>Pfade</v-system-bar>
    <v-treeview :items="hierarchy"></v-treeview>
  </v-card>
</template>
<script>
import { defineComponent } from "vue";

export default defineComponent({
  components: {},
  name: "PaperlessPath",
  props: ["paths", "documents"],
  computed: {
    hierarchy() {
      let result = [];
      let level = { result };

      this.paths.forEach((path) => {
        path.path.split("/").reduce((r, name) => {
          if (!r[name]) {
            r[name] = { result: [] };
            r.result.push({ ...path, children: r[name].result });
          }
          return r[name];
        }, level);
      });

      return result;
    },
  },
});
</script>
