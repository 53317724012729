<template>
  <span v-if="value">(path: {{ value.name }})</span>
</template>
<script>
import { defineComponent } from "vue";

export default defineComponent({
  components: {},
  name: "PaperlessPath",
  props: ["value"],
});
</script>
