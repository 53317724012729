<template>
  <v-chip small dark v-if="value">type: {{ value.name }}</v-chip>
</template>
<script>
import { defineComponent } from "vue";

export default defineComponent({
  components: {},
  name: "PaperlessType",
  props: ["value"],
});
</script>
