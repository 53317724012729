<template>
  <Page color="grey" title="Playground">
    <v-toolbar flat>
      <v-text-field
        type="search"
        v-model="search"
        placeholder="Suche"
        clearable
        class="mr-2"
        hideDetails
      ></v-text-field>

      <v-dialog v-model="dialog" width="500">
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="success" v-bind="attrs" v-on="on"
            ><v-icon>mdi-plus</v-icon></v-btn
          >
        </template>

        <v-card>
          <v-system-bar> Datei hinzufügen </v-system-bar>
          <v-container>
            <v-form>
              <v-file-input
                v-model="file"
                accept=".doc,.docx,.pdf,.xls,.xlsx,.ppt,.pptx"
                label="Datei auswählen"
              ></v-file-input>
            </v-form>
            {{ file.size }}
          </v-container>
          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="uploadDocument">
              speichern
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-toolbar>

    <PaperlessPathTree :paths="storagePaths" :documents="documents">
    </PaperlessPathTree>
    <v-card :loading="loading" class="mt-4">
      <v-system-bar>Dokumente</v-system-bar>
      <v-list>
        <v-list-item v-for="document in documents" :key="document.id">
          <v-list-item-icon>
            <v-icon :color="getColor(document.mime_type)">
              {{ getIcon(document.mime_type) }}</v-icon
            >
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title
              >{{ document.title }}
              <PaperlessPath
                :value="getStoragePath(document.storage_path)"
              ></PaperlessPath>
            </v-list-item-title>
            <v-list-item-subtitle>
              <v-chip small>{{ formatDate(document.modified) }}</v-chip>
              <PaperlessTag
                v-for="tag in document.tags"
                :key="tag"
                :value="getTag(tag)"
              ></PaperlessTag>
              <PaperlessType
                :value="getDocumentType(document.document_type)"
              ></PaperlessType>
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn icon @click="deleteDocument(document.id)"
              ><v-icon>mdi-trash-can</v-icon></v-btn
            >
          </v-list-item-action>
          <v-list-item-action>
            <v-btn
              icon
              :href="
                'http://localhost:8000/api/documents/' +
                document.id +
                '/download/'
              "
              ><v-icon>mdi-download</v-icon></v-btn
            ></v-list-item-action
          >
          <v-list-item-action>
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on"
                  ><v-icon> mdi-eye</v-icon></v-btn
                >
              </template>

              <img
                width="250"
                :src="
                  'http://localhost:8000/api/documents/' +
                  document.id +
                  '/thumb/'
                "
              />
            </v-tooltip>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-card>
    <v-row>
      <v-col cols="8">
        <v-card class="mt-4">
          <v-system-bar color="error">Dokumentation</v-system-bar>
          <v-card-text>
            <Markdown :value="mdText" />
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="4">
        <v-card class="mt-4">
          <v-system-bar color="error">Links</v-system-bar>

          <v-list>
            <v-list-item v-for="link in links" :key="link.url" :href="link.url">
              <v-list-item-content>
                <v-list-item-title>{{ link.title }}</v-list-item-title>
                <v-list-item-subtitle>{{ link.url }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
  </Page>
</template>
<script>
const mdText = `
Hinweise:

- Tags, Path und Type kommen per ID, müssen nachgeschlagen werden

Offene Fragen:

- Pfade: sind nicht hierarchisch, könnten aber nachgebaut werden: "anleitung" und dann "anleitung/byod". Rechte müssten aber auf jeder Ebene gesetzt werden
- jeder User ein Account -> müsste Tokens generieren, wären aber persönlich. Können user-Account auch erzeugt werden? (ev. direkt in DB, alles bis auf PW)
- je Level einen account: LK, SuS, Admin. Token setzen
- user können über rest synchronisiert werden

`;
const links = [
  {
    title: "rest-dok",
    url: "https://docs.paperless-ngx.com/api/",
  },
  {
    title: "oauth mit azure",
    url: "https://www.youtube.com/watch?v=0vwf5ksjfmE",
  },
  {
    title: "ldap",
    url: "https://github.com/paperless-ngx/paperless-ngx/discussions/3228",
  },
  {
    title: "ldap",
    url: "https://help.univention.com/t/ldap-with-paperless-ngx/23734gs",
  },
];
import { defineComponent } from "vue";
import { debounce } from "lodash";
import PaperlessPath from "./components/PaperlessPath.vue";
import PaperlessPathTree from "./components/PaperlessPathTree.vue";
import PaperlessTag from "./components/PaperlessTag.vue";
import PaperlessType from "./components/PaperlessType.vue";

export default defineComponent({
  components: { PaperlessPath, PaperlessPathTree, PaperlessTag, PaperlessType },
  name: "Playground",
  data() {
    return {
      links,
      mdText,
      loading: false,
      dialog: false,
      search: "",
      file: {},

      token: "b9498b25987eea28a38f6df92636109c16bfc329",
      token_teacher: "teacher",
      token_student: "student",
      documents: [],
      tags: [],
      documentTypes: [],
      storagePaths: [],
    };
  },
  computed: {
    getOptions() {
      return {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Token " + this.token,
          Host: "localhost:8000",
        },
      };
    },
  },
  watch: {
    search() {
      this.listDocuments();
    },
  },
  methods: {
    getStoragePath(id) {
      return this.storagePaths.find((el) => el.id == id);
    },
    getTag(id) {
      return this.tags.find((el) => el.id == id);
    },
    getDocumentType(id) {
      return this.documentTypes.find((el) => el.id == id);
    },
    formatDate(date) {
      return new Date(date).toLocaleDateString("de-CH");
    },
    getFileType(mime_type) {
      var type = mime_type.includes("/") ? mime_type.split("/")[1] : mime_type;
      type = type.includes(".") ? type.split(".")[3] : type;
      return type;
    },
    getColor(mime_type) {
      switch (this.getFileType(mime_type)) {
        case "pdf":
          return "#ff0000";
        case "png":
        case "tiff":
        case "jpeg":
          return "info";
        case "document":
          return "#1A5CBD";
        case "presentation":
          return "#D35230";
        case "sheet":
          return "#107C41";
        default:
          return "";
      }
    },
    getIcon(mime_type) {
      switch (this.getFileType(mime_type)) {
        case "pdf":
          return "mdi-file-document";
        case "png":
        case "tiff":
        case "jpeg":
          return "mdi-file-image";
        case "document":
          return "mdi-file-word";
        case "presentation":
          return "mdi-file-powerpoint";
        case "sheet":
          return "mdi-file-excel";
        default:
          return "";
      }
    },
    async deleteDocument(id) {
      var options = {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          Authorization: "Token " + this.token,
          Host: "localhost:8000",
        },
      };
      await fetch("http://localhost:8000/api/documents/" + id + "/", options);
    },
    async getData() {
      this.tags = (
        await (
          await fetch("http://localhost:8000/api/tags/", this.getOptions)
        ).json()
      ).results;
      this.documentTypes = (
        await (
          await fetch(
            "http://localhost:8000/api/document_types/",
            this.getOptions
          )
        ).json()
      ).results;
      this.storagePaths = (
        await (
          await fetch(
            "http://localhost:8000/api/storage_paths/",
            this.getOptions
          )
        ).json()
      ).results;
    },
    listDocuments: debounce(async function () {
      this.loading = true;
      this.documents = (
        await (
          await fetch(
            this.search
              ? "http://localhost:8000/api/documents/?search=" + this.search
              : "http://localhost:8000/api/documents/",
            this.getOptions
          )
        ).json()
      ).results;
      this.loading = false;
    }, 500),
    async uploadDocument() {
      var formData = new FormData();
      formData.append("document", this.file);
      var options = {
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: "Token " + this.token,
          Host: "localhost:8000",
        },
        body: formData,
      };
      await fetch(
        "http://localhost:8000/api/documents/post_document/",
        options
      );
    },
  },

  async created() {
    this.loading = true;
    await this.getData();
    await this.listDocuments();
    this.loading = false;
  },
});
</script>
