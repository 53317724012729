<template>
  <v-chip
    small
    :text-color="value.text_color"
    label
    :color="value.color"
    v-if="value"
    >tag: {{ value.name }}</v-chip
  >
</template>
<script>
import { defineComponent } from "vue";

export default defineComponent({
  components: {},
  name: "PaperlessTag",
  props: ["value"],
});
</script>
